const URL_STORAGE_KEY = "__md2graphql_URL" as const;
const TOKEN_STORAGE_KEY = "__md2graphql_TOKEN" as const;

export const getUrl = () => {
  return globalThis.localStorage.getItem(URL_STORAGE_KEY);
};

export const getToken = () => {
  return globalThis.localStorage.getItem(TOKEN_STORAGE_KEY);
};

export const settingSetup = () => {
  const url = getUrl();
  const token = getToken();

  const isUrl = typeof url === "string" && url.length > 0;
  const isToken = typeof token === "string" && token.length > 0;

  if (isUrl && isToken) {
    return;
  }

  if (!isUrl) {
    const newUrl = globalThis.prompt(
      `Please supply your GraphQL API URL #nrV8HM`
    );
    if (newUrl === null || newUrl.length === 0) {
      globalThis.alert(`Invalid url #6ym8Sa`);
    } else {
      globalThis.localStorage.setItem(URL_STORAGE_KEY, newUrl);
    }
  }

  if (!isToken) {
    const newToken = globalThis.prompt(`Please supply your token #cOYDvG`);
    if (newToken === null || newToken.length === 0) {
      globalThis.alert(`Invalid token #wXr9eB`);
    } else {
      globalThis.localStorage.setItem(TOKEN_STORAGE_KEY, newToken);
    }
  }

  // NOTE: The simplest option is to reload the whole page here, because the
  // baseApi has already been created at this point.
  globalThis.window.location.reload();
};

export const resetSettings = () => {
  globalThis.localStorage.removeItem(URL_STORAGE_KEY);
  globalThis.localStorage.removeItem(TOKEN_STORAGE_KEY);
};
