import { api } from 'src/app/api/baseApi';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CreateNewFileInput = {
  /** The commit message to use when commiting this file */
  commitMessage?: InputMaybe<Scalars['String']>;
  contents: Scalars['String'];
  /** The file path (including filename) relative to the repo */
  path: Scalars['String'];
  repoId: Scalars['String'];
};

export type CreateNewFileResult = {
  __typename?: 'CreateNewFileResult';
  commitHash: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  /** The basename (file name + extension) */
  basename: Scalars['String'];
  /** The sha1 checksum of the file's contents */
  checksum: Scalars['String'];
  /** String contents of the file, utf8. */
  content: Scalars['String'];
  /** The file extension (without a leading .) */
  extension: Scalars['String'];
  id: Scalars['ID'];
  /** The file path (within its repo). */
  path: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createNewFile: CreateNewFileResult;
  pullRepo: PullResult;
  setFileContents: SetFileContentsResult;
};


export type MutationCreateNewFileArgs = {
  data: CreateNewFileInput;
};


export type MutationPullRepoArgs = {
  data: PullReposInput;
};


export type MutationSetFileContentsArgs = {
  data: SetFileInput;
};

export type PullReposInput = {
  repoId: Scalars['String'];
};

export type PullResult = {
  __typename?: 'PullResult';
  commitHash: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  repo: Repo;
  repos: Array<Repo>;
};


export type QueryRepoArgs = {
  id: Scalars['String'];
};

export type Repo = {
  __typename?: 'Repo';
  ahead: Scalars['Float'];
  behind: Scalars['Float'];
  commitHash: Scalars['String'];
  file: File;
  files: Array<File>;
  id: Scalars['ID'];
  isClean: Scalars['Boolean'];
  name: Scalars['String'];
};


export type RepoFileArgs = {
  fileId: Scalars['String'];
};


export type RepoFilesArgs = {
  limit?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type SetFileContentsResult = {
  __typename?: 'SetFileContentsResult';
  commitHash: Scalars['String'];
};

export type SetFileInput = {
  /** The sha1 checksum of the file's existing contents */
  checksum: Scalars['String'];
  /** The commit message to use when commiting this file */
  commitMessage?: InputMaybe<Scalars['String']>;
  contents: Scalars['String'];
  fileId: Scalars['String'];
  repoId: Scalars['String'];
};

export type GetFileQueryVariables = Exact<{
  repoId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type GetFileQuery = { __typename?: 'Query', repo: { __typename?: 'Repo', file: { __typename?: 'File', id: string, basename: string, path: string, extension: string, checksum: string, content: string } } };

export type UpdateFileContentsMutationVariables = Exact<{
  data: SetFileInput;
}>;


export type UpdateFileContentsMutation = { __typename?: 'Mutation', setFileContents: { __typename?: 'SetFileContentsResult', commitHash: string } };

export type GetReposQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReposQuery = { __typename?: 'Query', repos: Array<{ __typename?: 'Repo', id: string, name: string }> };

export type GetRepoFilesQueryVariables = Exact<{
  repoId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
}>;


export type GetRepoFilesQuery = { __typename?: 'Query', repo: { __typename?: 'Repo', id: string, name: string, commitHash: string, isClean: boolean, ahead: number, behind: number, files: Array<{ __typename?: 'File', id: string, basename: string, path: string, extension: string }> } };


export const GetFileDocument = `
    query GetFile($repoId: String!, $fileId: String!) {
  repo(id: $repoId) {
    file(fileId: $fileId) {
      id
      basename
      path
      extension
      checksum
      content
    }
  }
}
    `;
export const UpdateFileContentsDocument = `
    mutation UpdateFileContents($data: SetFileInput!) {
  setFileContents(data: $data) {
    commitHash
  }
}
    `;
export const GetReposDocument = `
    query GetRepos {
  repos {
    id
    name
  }
}
    `;
export const GetRepoFilesDocument = `
    query GetRepoFiles($repoId: String!, $search: String) {
  repo(id: $repoId) {
    id
    name
    commitHash
    isClean
    ahead
    behind
    files(search: $search, limit: 50) {
      id
      basename
      path
      extension
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetFile: build.query<GetFileQuery, GetFileQueryVariables>({
      query: (variables) => ({ document: GetFileDocument, variables })
    }),
    UpdateFileContents: build.mutation<UpdateFileContentsMutation, UpdateFileContentsMutationVariables>({
      query: (variables) => ({ document: UpdateFileContentsDocument, variables })
    }),
    GetRepos: build.query<GetReposQuery, GetReposQueryVariables | void>({
      query: (variables) => ({ document: GetReposDocument, variables })
    }),
    GetRepoFiles: build.query<GetRepoFilesQuery, GetRepoFilesQueryVariables>({
      query: (variables) => ({ document: GetRepoFilesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetFileQuery, useLazyGetFileQuery, useUpdateFileContentsMutation, useGetReposQuery, useLazyGetReposQuery, useGetRepoFilesQuery, useLazyGetRepoFilesQuery } = injectedRtkApi;

