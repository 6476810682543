import React from "react";
import { Route, Routes } from "react-router-dom";
import { FileScene } from "src/scenes/File/File.scene";
import { HomeScene } from "../scenes/Home/Home.scene";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeScene />} />
      <Route path="/file/:repoId/:fileId" element={<FileScene />} />
    </Routes>
  );
};
