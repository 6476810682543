import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { GraphQLClient } from "graphql-request";
import { getToken, getUrl } from "../settings";

export const client = new GraphQLClient(
  getUrl() || "http://localhost:4000/graphql"
);

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client,
    requestHeaders: {
      Authorization: getToken() || "",
    },
  }),
  endpoints: () => ({}),
});
