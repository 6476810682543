/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetRepoFilesQuery } from "src/app/api/generated";

export const Files = ({ repoId }: { repoId: string }) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { data, error, isLoading } = useGetRepoFilesQuery({
    repoId,
    search,
  });

  const files = data?.repo.files || [];

  return (
    <div>
      {typeof error === "undefined" ? null : (
        <Paper
          elevation={4}
          css={css`
            padding: 10px;
          `}
        >
          <Typography variant="h2" color="red">
            Error
          </Typography>
          <Typography>{error.message}</Typography>
        </Paper>
      )}

      <div
        css={css`
          padding-top: 20px;
          padding-bottom: 20px;
          display: flex;
        `}
      >
        <TextField
          id="search"
          label="Search"
          autoFocus
          value={search}
          onChange={async (e) => setSearch(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              if (files.length === 0) {
                return;
              }
              const [first] = files;
              navigate(
                `/file/${repoId}/${globalThis.encodeURIComponent(first.id)}`
              );
            }
          }}
          css={css`
            min-width: 300px;
          `}
        />{" "}
        <Button
          title="Today's journal"
          onClick={() => {
            setSearch(`daily.journal.${dayjs().format("YYYY-MM-DD")}`);
          }}
        >
          Today's journal
        </Button>
      </div>

      {isLoading ? null : (
        <Paper elevation={2}>
          <List>
            {files.map(({ id }) => (
              <ListItem disablePadding key={id}>
                <ListItemButton
                  onClick={() => {
                    navigate(
                      `/file/${repoId}/${globalThis.encodeURIComponent(id)}`
                    );
                  }}
                >
                  <ListItemText>{id}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </div>
  );
};
