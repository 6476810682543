/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Paper, TextField, Typography } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import fm from "front-matter";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetFileQuery,
  useUpdateFileContentsMutation,
} from "src/app/api/generated";
import { LayoutComponent } from "src/components/Layout/Layout.component";

type Params = {
  repoId: string;
  fileId: string;
};

export const FileScene = () => {
  const { repoId, fileId } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetFileQuery({ repoId, fileId });
  const [updateFileContents] = useUpdateFileContentsMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [frontmatterEditorValue, setFrontmatterEditorValue] = useState("");
  const [markdownEditorValue, setMarkdownEditorValue] = useState("");

  const { markdown, frontmatter } = useMemo(() => {
    if (typeof data === "undefined") {
      return { markdown: "", frontmatter: "" };
    }
    const { body, frontmatter } = fm(data.repo.file.content);
    return { markdown: body, frontmatter: frontmatter || "" };
  }, [data]);

  return (
    <LayoutComponent>
      <Typography variant="h1">File</Typography>
      {typeof error === "undefined" ? null : (
        <Paper elevation={4}>
          <Typography variant="h2">Error</Typography>
          <Typography>{error.message}</Typography>
        </Paper>
      )}
      <Typography>
        {repoId} - {fileId}
      </Typography>
      {isLoading || typeof data === "undefined" ? null : isEditing ? (
        <>
          <TextField
            defaultValue={frontmatter}
            multiline
            rows={frontmatter.split("\n").length + 3}
            fullWidth
            onChange={(e) => setFrontmatterEditorValue(e.target.value)}
          />
          <MDEditor
            value={markdownEditorValue}
            onChange={(v) => {
              if (typeof v === "string") {
                setMarkdownEditorValue(v);
              }
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={async () => {
              await updateFileContents({
                data: {
                  fileId,
                  repoId,
                  checksum: data.repo.file.checksum,
                  contents: `---\n${frontmatterEditorValue}\n---\n${markdownEditorValue}`,
                },
              });
              alert("Successfully updated. #Ui6Tqe");
              navigate(-1);
            }}
          >
            Save
          </Button>
        </>
      ) : (
        <>
          <Paper
            elevation={2}
            css={css`
              min-height: 2rem;
              margin-top: 1rem;
              margin-bottom: 1rem;
              padding: 2px;
            `}
          >
            <pre>{frontmatter}</pre>
          </Paper>
          <Paper
            elevation={2}
            css={css`
              min-height: 2rem;
              margin-bottom: 1rem;
              padding: 2px;
            `}
          >
            {markdown === "" ? (
              <Typography>Empty file</Typography>
            ) : (
              <MDEditor.Markdown source={markdown} />
            )}
          </Paper>
          <Button
            variant="contained"
            onClick={() => {
              setFrontmatterEditorValue(frontmatter);
              setMarkdownEditorValue(markdown);
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        </>
      )}
    </LayoutComponent>
  );
};
