import { Container } from "@mui/material";
import React, { ReactNode } from "react";

export const LayoutComponent = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  return <Container maxWidth="lg">{children}</Container>;
};
