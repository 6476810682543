/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { useGetReposQuery } from "src/app/api/generated";
import { resetSettings } from "src/app/settings";
import { LayoutComponent } from "src/components/Layout/Layout.component";
import { Files } from "./components/Files/Files.component";

export const HomeScene = () => {
  const { data, error, isLoading } = useGetReposQuery();
  const [repoId, setRepoId] = useState("");

  return (
    <LayoutComponent>
      <Typography variant="h1">Home</Typography>
      {typeof error === "undefined" ? null : (
        <Paper elevation={3}>
          <Typography>{error.message}</Typography>
        </Paper>
      )}
      <div>
        {isLoading
          ? null
          : data?.repos.map(({ id, name }) => (
              <Button
                key={id}
                title={name}
                variant="contained"
                color={repoId === id ? "success" : "inherit"}
                onClick={() => setRepoId(id)}
              >
                {name}
              </Button>
            ))}
      </div>

      {repoId === "" ? null : <Files repoId={repoId} />}

      <div
        css={css`
          margin-top: 100vh;
        `}
      >
        <Button
          onClick={() => {
            if (globalThis.confirm(`Are you sure? #zU2Gj7`)) {
              resetSettings();
              globalThis.window.location.reload();
            }
          }}
          color="warning"
        >
          Reset settings
        </Button>
      </div>
    </LayoutComponent>
  );
};
